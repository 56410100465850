import React, { FC, useContext, useEffect, useState } from 'react';
import { Container, DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';

import './SearchPageContent.scss';
import connectHits from 'react-instantsearch-core/dist/es/connectors/connectHits';
import { CartContext } from 'context/CartContextProvider/CartContextProvider';
import useShopifyHelper from 'hooks/useShopifyHelper';
import QuestionsCarousel from '../../SupportPage/QuestionsCarousel';
import ProductCard from '../../ProductCard';
import { ISearchPageHits } from './model';
import Button from '../../../common/Button';
import { getQueryStringVal } from '../../../utils/useQueryParam';

const stepLoad = 4;

const SearchPageHits: FC<ISearchPageHits> = ({
  hits,
  resultText,
  buttonText,
  siteSettings: { isShopify, lang, usePriceSpider, isEanProductId } = {},
  waiSettings,
}) => {
  const [toShow, setToShow] = useState<number>(stepLoad);
  const search = getQueryStringVal('q');
  const loadMore = () => {
    setToShow((prevValue) => prevValue + stepLoad);
  };
  const textHits = hits?.filter(
    (hit) => !hit.objectID?.startsWith('Product') && !hit.objectID?.startsWith('Bundle')
  );

  const productHits = hits?.filter((hit) => hit.objectID?.startsWith('Product'));

  const bundleHits = hits?.filter((hit) => hit.objectID?.startsWith('Bundle'));

  const { mergeCmsShopifyProducts } = useShopifyHelper({ isShopify, lang });
  const { purchaseSettings } = useContext(CartContext);
  const { itemsInBundlePlaceholder, saveLabel, addToCartTitle, soldOutTitle } = purchaseSettings;

  const productsWithPrices = mergeCmsShopifyProducts({ nodes: [...bundleHits, ...productHits] });

  const carouselHistForVisualize = [].concat(productsWithPrices);

  useEffect(() => {
    setToShow(stepLoad);
    if (usePriceSpider) {
      window?.PriceSpider?.rebind();
    }
  }, [JSON.stringify(hits)]);

  return (
    <Container fluid>
      <div className="search-content__hits">
        <div className="search-content__hits__result">
          {search ? (
            <>
              <span className="search-content__hits__result__quantity">
                {`${carouselHistForVisualize.length + textHits.length}`}
              </span>
              <span className="search-content__hits__result__text">{`${resultText}`}</span>
              <span>&#8216;</span>
              <span className="search-content__hits__result__query">{`${search}`}</span>
              <span>&#8216;</span>
            </>
          ) : null}
        </div>
        <div className="search-content__hits__result-block">
          {carouselHistForVisualize?.length > 0 ? (
            <QuestionsCarousel
              questionCards={carouselHistForVisualize}
              ariaNext={waiSettings?.ariaNext}
              ariaPrev={waiSettings?.ariaPrev}
              customCardsComponent={carouselHistForVisualize.map((carouselItem) => {
                if (carouselItem.link?.includes('bundles')) {
                  return (
                    <div className="product-card--search-bundles-theme">
                      <ProductCard
                        {...carouselItem}
                        isBundles
                        itemsInBundlePlaceholder={itemsInBundlePlaceholder}
                        saveLabel={saveLabel}
                        key={
                          carouselItem.cardLink?.length
                            ? carouselItem.cardLink[0].url
                            : carouselItem.link
                        }
                        addToCartTitle={addToCartTitle}
                        soldOutTitle={soldOutTitle}
                        cartProductsData={carouselHistForVisualize}
                        isShopify={isShopify}
                        lang={lang}
                        usePriceSpider={usePriceSpider}
                        isEanProductId={isEanProductId}
                      />
                    </div>
                  );
                }

                return (
                  <ProductCard
                    {...carouselItem}
                    key={
                      carouselItem.cardLink?.length
                        ? carouselItem.cardLink[0].url
                        : carouselItem.link
                    }
                    productType="product"
                    usePriceSpider={usePriceSpider}
                  />
                );
              })}
            />
          ) : null}

          <div className="search-content__hits__result-block__text-hits">
            {textHits?.slice(0, toShow)?.map((hit) => (
              <div className="search-content__hits__result-block__text-hits__item" key={hit.link}>
                <Button variant="link" to={hit.link}>
                  <div className="search-content__hits__result-block__text-hits__item__title">
                    <DangerouslySetInnerHtml
                      html={
                        hit?._snippetResult?.seoMetaTitle?.value ||
                        hit?._snippetResult?.title?.value
                      }
                    />
                  </div>
                </Button>
                <div className="search-content__hits__result-block__text-hits__item__description">
                  <DangerouslySetInnerHtml
                    html={
                      hit?._snippetResult?.seoMetaDescription?.value ||
                      hit?._snippetResult?.description?.value
                    }
                  />
                </div>
              </div>
            ))}
            {textHits?.length > 0 && toShow < textHits?.length ? (
              <div className="search-content__hits__result-block__text-hits__button">
                <Button clickHandler={loadMore} variant="secondary">
                  {buttonText}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Container>
  );
};

const AlgoliaHits = connectHits(SearchPageHits);

export default AlgoliaHits;
