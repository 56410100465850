import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import './SearchPageContent.scss';
import SearchPageHits from './AlgoliaHits';
import { ISearchPageContent } from './model';

const SearchPageContent: FC<ISearchPageContent> = ({
  resultText,
  buttonText,
  siteSettings,
  allProduct,
  allBundle,
  waiSettings,
}) => (
  <div className={classNames('search-content')} data-testid="search-content">
    <SearchPageHits
      resultText={resultText}
      buttonText={buttonText}
      siteSettings={siteSettings}
      allProduct={allProduct}
      allBundle={allBundle}
      waiSettings={waiSettings}
    />
  </div>
);

export const query = graphql`
  fragment FragmentSearchPageContent on TSearchPageContent {
    properties {
      resultText
      buttonText
    }
    structure
  }
`;

export default SearchPageContent;
