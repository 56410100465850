import React, { FC } from 'react';
import classnames from 'classnames';

import { Link } from 'gatsby';

import IconCustom from 'common/IconCustom';

import { IBreadCrumbs } from './model';

import './BreadCrumbs.scss';

const BreadCrumbs: FC<IBreadCrumbs> = ({
  data,
  breadCrumbSeparatorIcon,
  theme = 'dark',
  popIndex = 1,
  isHideLastSeparator,
  useTextStyle,
}) => {
  const newData = data.slice(0, data.length - popIndex);

  return (
    <nav
      aria-label="Breadcrumb"
      data-test="BreadCrumb"
      className={classnames('breadcrumbs', `breadcrumbs--theme-${theme}`, {
        'breadcrumbs--2-lines-xs': newData.length > 2,
      })}
    >
      <ol
        className={classnames({
          [`bg--${useTextStyle?.[0]}`]: useTextStyle?.[0],
        })}
        itemscope=""
        itemType="https://schema.org/BreadcrumbList"
      >
        {newData?.map(({ name, link }, idx) =>
          popIndex === 0 && idx === newData.length - 1 ? (
            <li
              className="breadcrumbs__item"
              key={name}
              itemProp="itemListElement"
              itemscope=""
              itemType="https://schema.org/ListItem"
            >
              {isHideLastSeparator ? (
                <div>
                  <span className="breadcrumbs__name" itemProp="name">
                    {name}
                  </span>
                </div>
              ) : (
                <Link to={link} aria-current="page" itemprop="item">
                  <span className="breadcrumbs__name" itemProp="name">
                    {name}
                  </span>
                  <span aria-hidden="true" className="breadcrumbs__separator">
                    {breadCrumbSeparatorIcon ? <IconCustom icon={breadCrumbSeparatorIcon} /> : '/'}
                  </span>
                </Link>
              )}

              <meta itemProp="position" content={idx + 1} />
            </li>
          ) : (
            <li
              className="breadcrumbs__item"
              key={name}
              itemProp="itemListElement"
              itemscope=""
              itemType="https://schema.org/ListItem"
            >
              <Link to={link} itemprop="item">
                <span className="breadcrumbs__name" itemProp="name">
                  {name}
                </span>
                <span aria-hidden="true" className="breadcrumbs__separator">
                  {breadCrumbSeparatorIcon ? <IconCustom icon={breadCrumbSeparatorIcon} /> : '/'}
                </span>
              </Link>
              <meta itemProp="position" content={idx + 1} />
            </li>
          )
        )}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
